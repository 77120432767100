<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >员工名称：
            <el-input
              placeholder="请输入员工名称"
              class="ipt_width"
              clearable
              v-model="parameter.userName"
            >
            </el-input
          ></span>
          <span
            >项目名称：
            <el-select
              v-model="parameter.projectId"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请输入或选择项目名称"
              class="ipt_width project_width"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option> </el-select
          ></span>

          <span
            >工时日期：
            <el-date-picker
              class="date_width"
              v-model="createdDate"
              :pickerOptions="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker
          ></span>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <!-- <el-button type="primary" icon="el-icon-edit" @click="clear_serach(1)">清空</el-button> -->
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="activeName" class="fullScreenMainHeader">
            <el-tab-pane name="1" label="待审核"></el-tab-pane>
            <el-tab-pane name="2" label="已审核"></el-tab-pane>
            <el-tab-pane name="3" label="不通过"></el-tab-pane>
          </el-tabs>
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :key="Math.random()"
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            height="string"
            v-loading="loading"
            @cell-click="handleCellClick"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              label="员工名称"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="departmentName"
              label="所属部门"
              width="110"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="projectName"
              label="项目"
              min-width="240"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.manhourType == 0">{{ scope.row.projectName }}</span>
                <span v-if="scope.row.manhourType == 1">{{ scope.row.workName }} </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="workContent"
              label="工作内容"
              min-width="240"
            ></el-table-column>
            <el-table-column align="center" prop="manhourType" label="有无重要活动" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.outgoingType == 'HAVE'"
                  ><el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="showOutReportDetail(scope.row)"
                    >查看报告</el-button
                  ></span
                >
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="isTraveling" label="是否外勤" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.isTraveling == 'Y'"
                  ><el-button type="primary" size="mini" plain @click="showOutWorkDetail(scope.row)"
                    >查看报告</el-button
                  ></span
                >
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="manhourType" label="工作类型" width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.manhourType == 0">项目级</span>
                <span v-if="scope.row.manhourType == 1">组织级</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="checkEmployee"
              v-if="activeName != 1"
              label="审核人"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="checkOpinion"
              v-if="activeName != 1"
              label="审核意见"
              width="200"
              :show-overflow-tooltip="false"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="manhourDate"
              label="工时日期"
              width="110"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="submitDate"
              label="填报时间"
              width="160"
            ></el-table-column>
            <el-table-column
              align="center"
              v-if="activeName != 1"
              prop="checkTime"
              label="审批时间"
              width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="hour"
              label="填报工时"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="auditHour"
              label="审核工时"
              v-if="activeName != 1"
              width="90"
            ></el-table-column>

            <el-table-column
              prop="title"
              width="250"
              align="center"
              fixed="right"
              label="操作"
              v-if="activeName == 3"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  >重新提交</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                  @click.stop="day_c(scope.row)"
                  >修改当天工时</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Remove_Bgc"
                  @click.stop="del(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <OutReportDetail
      v-if="isOutReportDetailShow"
      :isOutReportDialogShow.sync="isOutReportDetailShow"
      :outReportData="manhourOutgoing"
    >
    </OutReportDetail>

    <EditDialog width="35%" :isShow.sync="showEditDialog" :loading.sync="editDialogLoading">
      <div slot="title" class="title">工时重新提交</div>
      <div slot="content" class="content" v-loading="editDialogLoading">
        <div class="dg_title">{{ dg_form.submitDate | dateFormat }}</div>
        <el-form label-width="7em" :model="dg_form" ref="dg_form">
          <el-form-item label="填报人" prop="userName" ref="userName">
            <el-input v-model="dg_form.userName" disabled></el-input>
          </el-form-item>
          <el-form-item label="填报部门" prop="departmentName" ref="departmentName">
            <el-input v-model="dg_form.departmentName" disabled></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="manhourType" ref="manhourType">
            <!-- 普通成员 -->
            <!-- <el-select
              v-model="dg_form.manhourType"
              @change="sel_blur"
              clearable
              filterable
              placeholder="请选择项目类型"
              class="ipt_width"
              v-if="!isZHB"
            > -->
            <el-select
              v-model="dg_form.manhourType"
              @change="sel_blur"
              clearable
              filterable
              placeholder="请选择项目类型"
              class="ipt_width"
            >
              <el-option label="项目级" :value="0"> </el-option>
              <el-option label="组织级" :value="1"> </el-option>
            </el-select>
            <!-- 综合部成员不可选择类型 -->
            <!-- <el-select
              v-model="dg_form.manhourType"
              @change="sel_blur"
              clearable
              filterable
              placeholder="请选择项目类型"
              class="ipt_width"
              v-else
            >
              <el-option label="项目级" :value="0" v-if="onlyProjectHour && !onlyOrgHour">
              </el-option>
              <el-option label="组织级" :value="1" v-if="!onlyProjectHour && onlyOrgHour">
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item
            label="项目名称"
            prop="workName"
            ref="workName"
            v-if="dg_form.manhourType == 0"
          >
            <el-select
              @input="selectChangeParent($event)"
              v-model="dg_form.projectId"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请输入或选择项目名称"
              class="ipt_width project_width"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组织名称" prop="deptId" ref="deptId" v-else>
            <!-- <el-select
              v-model="dg_form.companyType"
              clearable
              filterable
              placeholder="请选择组织名"
              class="ipt_width"
            >
              <el-option v-for="(item, i) in orgObject" :key="i" :label="i" :value="item">
              </el-option>
            </el-select> -->
            <DeptCascader
              :placeholder="'请选择组织名称'"
              :deptList="deptList"
              :deptIdArr="dg_form.deptIdArr"
              style="width: 100%"
              :clearable="false"
              @changeValue="dg_form.deptId = $event"
              @changeValueArr="dg_form.deptIdArr = $event"
            />
          </el-form-item>
          <el-form-item label="填报工时" prop="hour" ref="hour">
            <el-input v-model="dg_form.hour"></el-input>
          </el-form-item>
          <el-form-item label="工作内容" prop="workContent" ref="workContent">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
              v-model="dg_form.workContent"
            ></el-input>
          </el-form-item>
          <el-form-item label="有无重要活动">
            <el-radio-group v-model="dg_form.outgoingType" disabled style="margin: 0 50px 0 20px">
              <el-radio label="HAVE">有</el-radio>
              <el-radio label="NO">无</el-radio>
            </el-radio-group>
            <el-button
              type="warning"
              plain
              size="mini"
              @click.stop="changeOutReport()"
              v-if="dg_form.outgoingType == 'HAVE'"
              >修改重要活动报告</el-button
            >
            <el-button
              type="success"
              plain
              size="mini"
              @click.stop="addOutReport()"
              v-else-if="dg_form.outgoingType == 'NO'"
              >添加重要活动报告</el-button
            >
          </el-form-item>
          <el-form-item label="是否外勤">
            <el-radio-group v-model="dg_form.isTraveling" disabled style="margin: 0 50px 0 20px">
              <el-radio label="Y">是</el-radio>
              <el-radio label="N">否</el-radio>
            </el-radio-group>
            <!-- <el-button
              type="warning"
              plain
              size="mini"
              @click.stop="changeOutWork"
              v-if="dg_form.isTraveling == 'Y'"
              >修改外勤报告</el-button
            >
            <el-button
              type="success"
              plain
              size="mini"
              @click.stop="addOutWork"
              v-else-if="dg_form.isTraveling == 'N'"
              >添加外勤报告</el-button
            > -->
          </el-form-item>
          <el-divider style="width: 70%"></el-divider>
          <el-form-item label="退回说明" prop="checkOpinion" ref="checkOpinion">
            <el-input
              disabled
              type="textarea"
              v-model="dg_form.checkOpinion"
              :autosize="{ minRows: 2, maxRows: 2 }"
              placeholder="请输入"
              @keyup.enter.native="dg"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" @click="dg">提交</el-button>
        <el-button type="warning" plain @click="showEditDialog = false">关闭</el-button>
      </div>
    </EditDialog>

    <EditDialog
      width="50%"
      :isShow.sync="isOutReportDialogShow"
      :isReturn="true"
      @closeFn="isOutReportDialogShow = false"
    >
      <div slot="title" class="title">重要活动报告</div>
      <div slot="content" class="content" style="min-height: 480px; padding-left: 20px">
        <el-form
          style="width: 100%"
          :model="outReportData"
          :rules="outReportRules"
          ref="outReportForm"
          label-width="107px"
          label-position="left"
        >
          <el-form-item label="报告类型" prop="type">
            <el-radio-group v-model="outReportData.type">
              <el-radio label="WRITTEN">书面报告</el-radio>
              <el-radio label="ORAL">口头报告</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="活动开始时间"
            style="width: 50%; display: inline-block"
            prop="startDate"
          >
            <el-date-picker
              v-model="outReportData.startDate"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="timePickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="活动结束时间"
            style="width: 50%; display: inline-block"
            prop="endDate"
          >
            <el-date-picker
              v-model="outReportData.endDate"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="endDateOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="活动地点" prop="place">
            <el-input @input="$forceUpdate()" v-model.trim="outReportData.place"></el-input>
          </el-form-item>
          <el-form-item :error="outReportData.fileErrMsg">
            <template slot="label">
              <template> 附件</template>
            </template>
            <div class="upload-btn-box">
              <div class="icon-out">
                <Upload type="WCHDBG" :fields="fields" @upload="upload"></Upload>
              </div>

              <div>
                <el-button
                  type="success"
                  size="small"
                  style="margin-left: 50px"
                  v-if="outReportData.type == 'WRITTEN'"
                  @click="downloadFile"
                  >下载重要活动报告模板</el-button
                >
                <el-alert
                  style="margin-left: 50px"
                  v-else
                  title="如有微信截图，请在附件上传中上传"
                  type="info"
                >
                </el-alert>
              </div>
            </div>

            <div
              v-for="(n, index) in outReportData.manhourFileList"
              :key="index"
              style="margin-top: 5px"
              class="file-list"
            >
              <a
                class="fj-text file-name"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${n.filePath}`"
              >
                {{ n.fileName }}
              </a>

              <i @click="fileDel(index)" class="iconfont iconerror icon-out"></i>
            </div>
          </el-form-item>
          <el-form-item label="活动说明" prop="content">
            <template slot="label">
              活动说明
              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content" style="font-size: 18px; color: #000">
                  编写提示：<br />
                  一、汇报事由：XX时间参与了XX组织的XX活动，活动主题是XXX，同时参加活动的还有XX单位/企业，
                  <br />　　我(或我司XX)以XX角色（汇报者或参会人员等）在活动中作出了汇报（或其他事项）。<br />
                  二、梳理活动主要情况，汇报需要特别关注的重点事项。<br />
                  三、结合实际，针对重点问题提出切实可行的建议。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <el-input
              type="textarea"
              @input="$forceUpdate()"
              v-model.trim="outReportData.content"
              :autosize="{ minRows: 8, maxRows: 9999 }"
              placeholder="编写提示：
一、汇报事由：XX时间参与了XX组织的XX活动，活动主题是XXX，同时参加活动的还有XX单位/企业，我(或我司XX)以XX角色（汇报者或参会人员等）在活动中作出了汇报（或其他事项）。
二、梳理活动主要情况，汇报需要特别关注的重点事项。
三、结合实际，针对重点问题提出切实可行的建议。"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="footer" v-if="!addTag">
        <el-button type="primary" plain @click="saveOutReport">保存</el-button>
        <el-button type="danger" plain @click="deleteOutReport">删除</el-button>
        <el-button type="info" plain @click="isOutReportDialogShow = false">关闭</el-button>
      </div>
      <div slot="footer" class="footer" v-else>
        <el-button type="primary" plain @click="saveOutReport">保存</el-button>
        <el-button type="info" plain @click="isOutReportDialogShow = false">关闭</el-button>
      </div>
    </EditDialog>

    <!-- 工时重新填报(弹出框) -->
    <edit-dlg
      v-if="showDayEditDialog"
      :ZHB_projectManager="ZHB_projectManager"
      :ZHB_member="ZHB_member"
      :showDayEditDialog="showDayEditDialog"
      :dayForm="dayForm"
      :getDate="today"
      @childEvent="callEventEdit"
    >
      >
    </edit-dlg>

    <el-dialog title="工作内容" :visible.sync="workContentDialogVisible" width="40%">
      <div class="dialogContentHeight">{{ workContentDialog }}</div>
    </el-dialog>

    <OutWorkDetail
      v-if="isOutWorkDetailShow"
      :isOutWorkDialogShow.sync="isOutWorkDetailShow"
      :outWorkInfo="manhourTraveling"
    />

    <EditDialog
      width="45%"
      :isShow.sync="isOutWorkDialogShow"
      :isReturn="true"
      @closeFn="isOutWorkDialogShow = false"
    >
      <div slot="title" class="title">外勤报告</div>
      <div slot="content" class="content" style="min-height: 480px; padding-left: 20px">
        <el-form style="width: 100%" :model="outWorkInfo" label-width="107px" label-position="left">
          <el-form-item>
            <template slot="label"> <span style="color: red">*</span> 证明人 </template>

            <el-select v-model="outWorkInfo.witness" filterable placeholder="请选择证明人">
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.userName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <span style="color: red">*</span> 附件
              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content" style="font-size: 18px; color: #000">
                  工作派遣、聊天记录、通知函。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <div class="upload-btn-box">
              <div class="icon-out">
                <Upload type="WQ" :fields="fields" @upload="outWorkUpload"></Upload>
              </div>
            </div>

            <div
              v-for="(n, index) in outWorkInfo.manhourFileList"
              :key="index"
              style="margin-top: 5px"
              class="file-list"
            >
              <a
                class="fj-text file-name"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${n.filePath}`"
              >
                {{ n.fileName }}
              </a>

              <i @click="outWorkDel(index)" class="iconfont iconerror icon-out"></i>
            </div>
          </el-form-item>
          <el-form-item label="外勤说明" prop="content" style="width: 100%">
            <el-input
              type="textarea"
              v-model.trim="outWorkInfo.content"
              :autosize="{ minRows: 8, maxRows: 9999 }"
              placeholder="请输入外勤说明"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="footer" v-if="!addsOutWorkTag">
        <el-button type="primary" plain @click="saveOutWork">保存</el-button>
        <el-button type="danger" plain @click="deleteOutWork">删除</el-button>
        <el-button type="info" plain @click="isOutWorkDialogShow = false">关闭</el-button>
      </div>
      <div slot="footer" class="footer" v-else>
        <el-button type="primary" plain @click="saveOutWork">保存</el-button>
        <el-button type="info" plain @click="isOutWorkDialogShow = false">关闭</el-button>
      </div>
    </EditDialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    EditDlg: () => import('./EditDlg.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    OutReportDetail: () => import('@/components/hour/out-report-detail.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    OutWorkDetail: () => import('@/components/hour/out-work-detail.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        userName: '',
      },
      pageSize: 0,
      form: {},
      dg_form: {},
      tableData: [],
      loading: false,
      activeName: '1',
      editDialogLoading: false,
      showEditDialog: false,
      createdDate: [],
      projectList: [],
      //当天修改工时数据
      dayForm: {},
      showDayEditDialog: false,
      today: '', //当天日期 -- 年-月-日
      orgObject: [],
      workContentDialogVisible: false,
      workContentDialog: '',
      ZHB_projectManager: [],
      ZHB_member: [],
      onlyProjectHour: false,
      onlyOrgHour: false,
      isZHB: false,
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },

      manhourOutgoing: null, //外出活动报告数据源
      isOutReportDetailShow: false,
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },

      outReportRules: {
        type: [{ required: true, message: '请选择报告类型', trigger: 'change' }],
        startDate: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        endDate: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        place: [{ required: true, message: '请输入活动地点', trigger: 'blur' }],
        content: [{ required: true, message: '请填写活动说明', trigger: 'blur' }],
      },
      addTag: false,
      isOutReportChange: false,
      isOutReportDialogShow: false, //用于标识当前外出活动弹框是新增弹框还是详情弹框
      outReportType: '', //用于判断当前外出活动报告对话框是项目组还是组织级
      canGoOutSubmit: true,
      //外出报告弹框数据源
      outReportData: {
        type: 'WRITTEN', //类型
        startDate: '', //实际开始时间
        endDate: '', //实际结束时间
        place: '',
        content: '',
        manhourFileList: [],
        fileErrMsg: '', //附件上传校验文字
      },
      proIdList: [],
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
      // 日期选择器配置快捷方式
      timePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            },
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            },
          },
        ],
      },
      endDateOptions: {
        disabledDate: time => {
          const startDate = new Date(this.outReportData.startDate)
          if (startDate) {
            return time.getTime() < startDate.getTime()
          } else {
            return false
          }
        },
      },
      deptList: [],
      deptNameList: [],
      manhourTraveling: {}, //外勤数据源
      isOutWorkDetailShow: false,
      addsOutWorkTag: false,
      isOutWorkDialogShow: false, // 外勤弹窗
      isOutWorkDialog: false,
      canOutWorkSubmit: true,
      // 外勤弹框数据源
      outWorkInfo: {
        manhourId: '',
        witness: '',
        content: '',
        manhourFileList: [],
        deleteManhourFileIdList: [],
      },
      userList: [],
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    activeName: {
      // immediate:true,//初始化立即执行
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.rePage()
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
    showEditDialog: {
      handler: function (val) {
        if (!val) {
          this.isOutReportChange = false
          this.isOutWorkDialog = false
        }
      },
    },
  },
  async created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    if (this.$route.query.isfill == 1) {
      this.activeName = '3'
    }

    await this.getData()
    await this.getProject()

    this.$api.sysDept
      .listDept()
      .then(res => {
        this.deptNameList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.user
      .listStaff({
        pageNow: 1,
        pageSize: 9999,
      })
      .then(res => {
        this.userList = res.data?.records.deepClone()
        this.userList = this.userList.filter(v => v.id != this.userInfo.staffId)
      })
      .catch(err => {
        console.log(err)
      })
    this.getOrgObj()
  },
  mounted() {
    // this.getZHBList()
  },
  methods: {
    async getProIdList() {
      const res = await this.$api.projectMemberSort.selectDetailStaffId()
      this.proIdList = res.data?.projectSortList || []
      this.OrganizeProList()
    },
    // 获取综合部成员信息
    // getZHBList() {
    //   this.ZHB_member = []
    //   this.ZHB_projectManager = []
    //   this.$api.user.listZhbMemberNotLeader().then(res => {
    //     res.data.forEach(item => {
    //       this.ZHB_member.push(item)
    //     })
    //   })
    //   this.$api.user.listZhbGroupLeader().then(res => {
    //     res.data.forEach(item => {
    //       this.ZHB_member.push(item)
    //       this.ZHB_projectManager.push(item)
    //     })
    //   })
    // },

    //* 判断该成员在是否是综合部成员，确在综合部扮演什么角色
    // getUserAuthority() {
    //   // 确定部门，结果为true说明该角色是综合部成员
    //   const deptResult = this.ZHB_member.some(item => this.userInfo.staffId == item)
    //   if (deptResult) {
    //     this.isZHB = true
    //     // 确定成员身份，如果为true说明是相互经理
    //     const staffResult = this.ZHB_projectManager.some(item => item == this.userInfo.staffId)
    //     if (staffResult) {
    //       // 说明这个人是综合部部门经理，只能显示组织级工时，隐藏项目级工时
    //       this.onlyProjectHour = false
    //       this.onlyOrgHour = true
    //     } else {
    //       // 这个人是综合部普通成员
    //       this.onlyProjectHour = true
    //       this.onlyOrgHour = false
    //     }
    //   } else {
    //     this.isZHB = false
    //     this.onlyProjectHour = false
    //     this.onlyOrgHour = false
    //   }
    // },

    getData() {
      this.loading = true
      this.parameter.userId = this.userInfo.staffId
      this.parameter.checkStatus = this.activeName
      if (this.createdDate) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else {
        this.parameter.beginDate = ''
        this.parameter.endDate = ''
      }

      this.$api.hour
        .getManhourList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == null) {
            this.tableData = []
          } else {
            let arr = []
            for (let i = 0; i < res.data.records.length; i++) {
              const e = res.data.records[i]
              e.manhourDate = new Date(e.manhourDate).format('yyyy-MM-dd')
              arr.push(e)
            }
            this.tableData = arr
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    rePage() {
      this.parameter = {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        userName: '',
      }
    },
    detail(row) {
      this.showEditDialog = true
      // this.getUserAuthority()
      this.dg_form = row.deepClone()
      this.dg_form.deleteOutgoingIdList = []
      this.dg_form.deleteTravelingIdList = []
    },
    day_c(row) {
      this.showDayEditDialog = true
      this.dayForm = row

      this.today = row.manhourDate
    },
    dg() {
      if (this.dg_form.manhourType == 0 && !this.dg_form.workName) {
        this.$message('请选择项目名！')
        return
      }

      if (this.dg_form.manhourType == 1 && !this.dg_form.deptId) {
        this.$message('请选择组织名称！')
        return
      }
      //处理后端穿了部门id会出bug的问题
      if (this.dg_form.manhourType == 0) {
        delete this.dg_form.departmentId
      }
      if (this.dg_form.manhourType == 1) {
        this.dg_form.departmentId = this.dg_form.deptId
        const dept = this.deptNameList.find(v => v.id === this.dg_form.deptId)
        if (dept) {
          this.dg_form.workName = dept.deptName
        }
      }

      this.editDialogLoading = true
      let arr = []
      this.dg_form.submitDate = new Date(this.dg_form.submitDate).getTime()
      this.dg_form.manhourDate = new Date(this.dg_form.manhourDate).getTime()
      this.dg_form.checkStatus = 1
      arr.push(this.dg_form)

      //判断是否提交过该项目
      this.$api.hour
        .checkXmManhour(this.dg_form)
        .then(res => {
          if (res.data) {
            this.$confirm('该项目当日已填报工时，请确认是否将其覆盖？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                arr[0].resubmitFlag = 1
                this.$api.hour
                  .saveBatchManhour(arr)
                  .then(res => {
                    this.getData()
                    this.editDialogLoading = false
                    this.showEditDialog = false
                  })
                  .catch(err => {
                    console.log(err)
                    this.editDialogLoading = false
                  })
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消',
                })
              })
          } else {
            this.$api.hour
              .saveBatchManhour(arr)
              .then(res => {
                this.getData()
                this.editDialogLoading = false
                this.showEditDialog = false
              })
              .catch(err => {
                console.log(err)
                this.editDialogLoading = false
              })
          }
        })
        .catch(err => {
          console.log(err)
          this.editDialogLoading = false
        })
    },
    handleReset() {
      this.createdDate = []
      this.parameter = {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      }
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getProject() {
      this.$api.project
        .listProjectToManhour({ pageSize: 10000 })
        .then(res => {
          this.projectList = res.data
          this.getProIdList()
        })
        .catch(err => {
          console.log(err)
        })
    },
    //整理返回回来的项目列表与排序列表
    OrganizeProList() {
      if (this.projectList && this.projectList.length > 0) {
        this.projectList.forEach(item => {
          let index = this.proIdList.findIndex(id => id == item.id)
          if (index !== -1) {
            //说明当前项目id在排序列表中存在，直接将item内容替换到排序列表在
            this.proIdList.splice(index, 1, item)
          } else {
            //如果没有找到，说明该项目是新项目，插入到排序列表最前
            this.proIdList.unshift(item)
          }
        })
        //再次渲染排序列表查看当前是否存在id
        this.proIdList = this.proIdList.filter(item => typeof item == 'object')
        this.projectList = [...this.proIdList]
      }
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    /**
     * 关闭弹出框
     * @param  data  子组件 传过来的值
     */
    callEventEdit(data) {
      this.getData()
      this.activeName = '3'
      this.showDayEditDialog = false
    },
    selectChangeParent(e) {
      const findResult = this.projectList.find(item => item.id == e)
      if (findResult) {
        this.dg_form.projectId = findResult.id
        this.dg_form.projectName = findResult.projectName
        this.dg_form.workName = findResult.projectName
      }
    },
    sel_blur() {
      // this.dg_form.companyType = ''
      this.dg_form.deptId = ''
      this.dg_form.deptIdArr = []
      this.dg_form.workName = ''
    },
    del(row) {
      this.$confirm('此操作将删除该条工时数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.hour
            .removeFailPassManhour(row.id)
            .then(res => {
              this.$message.success('删除成功！')
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    getOrgObj() {
      // this.$api.hour
      //   .organizationManhourType()
      //   .then(res => {
      //     this.orgObject = res.data
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })

      this.$api.sysDept
        .getUserDeptTree(this.userInfo.staffId)
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getObjectKey(value) {
      const findResult = Object.keys(this.orgObject).find(key => this.orgObject[key] == value)
      if (findResult) {
        return findResult
      } else {
        return ''
      }
    },

    // 打开预览工作内容
    handleCellClick(row, column) {
      if (column.label == '工作内容') {
        this.workContentDialog = row.workContent
        this.workContentDialogVisible = true
      }
    },

    changeOutReport() {
      if (this.isOutReportChange) {
        this.outReportData = { ...this.dg_form.manhourOutgoing }
        this.isOutReportDialogShow = true
      } else {
        this.outReportData = {}
        // 需要调接口获取外出活动内容
        this.$api.hour
          .selectManhourOutgoingDetailByManhourId(this.dg_form.id)
          .then(res => {
            if (res.data) {
              this.outReportData = res.data
              this.outReportData.deleteManhourFileIdList
                ? (this.outReportData.deleteManhourFileIdList =
                    this.outReportData.deleteManhourFileIdList)
                : (this.outReportData.deleteManhourFileIdList = [])

              this.isOutReportDialogShow = true
              this.addTag = false
              this.isOutReportChange = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    showOutReportDetail(row) {
      this.manhourOutgoing = null
      // 需要调接口获取外出活动内容
      this.$api.hour
        .selectManhourOutgoingDetailByManhourId(row.id)
        .then(res => {
          if (res.data) {
            this.manhourOutgoing = res.data
            this.isOutReportDetailShow = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    upload(r) {
      this.outReportData.manhourFileList.push(...r)
      this.outReportData.fileErrMsg = ''
    },

    fileDel(index) {
      if (this.outReportData.manhourFileList[index].id) {
        this.outReportData.deleteManhourFileIdList.push(
          this.outReportData.manhourFileList[index].id
        )
      }
      this.outReportData.manhourFileList = this.outReportData.manhourFileList.filter(
        (item, i) => i != index
      )
    },

    saveOutReport() {
      // 触发校验
      this.$refs.outReportForm.validate(valid => {
        if (valid) {
          //  && this.outReportData.manhourFileList.length > 0
          if (this.outReportData.manhourFileList) {
            this.dg_form.manhourOutgoing = { ...this.outReportData }
            this.dg_form.outgoingType = 'HAVE'
            this.isOutReportDialogShow = false
            this.isOutReportChange = true
            this.addTag = false
          }
          //  else {
          //   this.outReportData.fileErrMsg = '请上传附件'
          // }
        }
        // else {
        //   if (this.outReportData.manhourFileList && this.outReportData.manhourFileList.length > 0) {
        //     return false
        //   } else {
        //     this.outReportData.fileErrMsg = '请上传附件'
        //   }
        //   return false
        // }
      })
    },

    deleteOutReport() {
      if (this.outReportData.id) {
        this.dg_form.deleteOutgoingIdList = []
        this.dg_form.deleteOutgoingIdList.push(this.outReportData.id)
      }
      this.dg_form.manhourOutgoing = null
      this.dg_form.outgoingType = 'NO'
      this.isOutReportChange = true
      this.isOutReportDialogShow = false
    },

    addOutReport() {
      let defaultDate = new Date(this.dg_form.manhourDate)
      this.outReportData = {
        type: 'WRITTEN',
        startDate: defaultDate,
        endDate: defaultDate,
        place: '',
        content: '',
        manhourFileList: [],
        fileErrMsg: '',
      }
      this.addTag = true
      this.isOutReportDialogShow = true
    },

    changeOutWork() {
      if (this.isOutWorkDialog) {
        this.outWorkInfo = { ...this.dg_form.manhourTraveling }
        this.isOutWorkDialogShow = true
      } else {
        this.outWorkInfo = {}
        // 需要调接口获取外勤内容
        this.$api.hour
          .selectDetailByManhourId(this.dg_form.id)
          .then(res => {
            if (res.data) {
              this.outWorkInfo = res.data
              this.outWorkInfo.deleteManhourFileIdList
                ? (this.outWorkInfo.deleteManhourFileIdList =
                    this.outWorkInfo.deleteManhourFileIdList)
                : (this.outWorkInfo.deleteManhourFileIdList = [])

              this.isOutWorkDialogShow = true
              this.addsOutWorkTag = false
              this.isOutWorkDialog = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    showOutWorkDetail(row) {
      this.manhourTraveling = {}
      // 需要调接口获取外勤内容
      this.$api.hour
        .selectDetailByManhourId(row.id)
        .then(res => {
          if (res.data) {
            this.manhourTraveling = res.data
            this.isOutWorkDetailShow = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    outWorkUpload(r) {
      this.outWorkInfo.manhourFileList.push(...r)
    },

    outWorkDel(index) {
      if (this.outWorkInfo.manhourFileList[index].id) {
        this.outWorkInfo.deleteManhourFileIdList.push(this.outWorkInfo.manhourFileList[index].id)
      }
      this.outWorkInfo.manhourFileList = this.outWorkInfo.manhourFileList.filter(
        (item, i) => i != index
      )
    },

    saveOutWork() {
      // 触发校验
      if (!this.outWorkInfo.witness) {
        return this.$message.warning('请选择证明人')
      }
      if (this.outWorkInfo.manhourFileList && this.outWorkInfo.manhourFileList.length <= 0) {
        return this.$message.warning('请上传附件')
      }
      this.dg_form.manhourTraveling = { ...this.outWorkInfo }
      this.dg_form.isTraveling = 'Y'
      this.isOutWorkDialogShow = false
      this.isOutWorkDialog = true
      this.addsOutWorkTag = false
    },

    deleteOutWork() {
      if (this.outWorkInfo.id) {
        this.dg_form.deleteTravelingIdList = []
        this.dg_form.deleteTravelingIdList.push(this.outWorkInfo.id)
      }
      this.dg_form.manhourTraveling = null
      this.dg_form.isTraveling = 'N'
      this.isOutWorkDialog = true
      this.isOutWorkDialogShow = false
    },

    addOutWork() {
      this.outWorkInfo = {
        manhourId: '',
        witness: '',
        content: '',
        manhourFileList: [],
        deleteManhourFileIdList: [],
      }
      this.addsOutWorkTag = true
      this.isOutWorkDialogShow = true
    },

    async downloadFile() {
      window.location.href =
        'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/template/外出活动报告模板.docx'
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.dg_title {
  padding-bottom: 10px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.dialogContentHeight {
  height: 250px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 0px 20px;
}

.hour-audit {
  display: flex;
  .out-report {
    width: 50%;
    padding-right: 20px;
    border-right: 1px solid #efefef;
  }
  .text-center-btm {
    padding-left: 20px;
    width: 50%;
  }
}

.upload-btn-box {
  display: flex;
  align-items: center;
}
.icon-out {
  cursor: pointer;
  font-size: 28px;
  padding-left: 10px;
  vertical-align: middle;
}
.file-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.out-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .out-a {
    margin-top: 15px;
  }
}
.el-icon-question {
  font-size: 20px;
  color: #e6a23c;
}
</style>
